<template>
  <div class="memoryTip">
    <div class="container">
      <ul>
        <li>
          <p>
            请将左手食指放在
            <span class="keyBtn">Q</span>
            键上
          </p>
        </li>
        <li>
          <p>
            请将右手食指放在
            <span class="keyBtn">P</span>
            键上
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  methods: {},
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.$router.replace('/selfJudge');
    }, 3000);
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 1900px) {
  .memoryTip {
    display: table-cell;
    vertical-align: middle;
    .container {
      ul {
        text-align: center;
        padding: 205px 0;
        li {
          padding: 20px 0;
          p {
            font-size: 32px;
            font-family: 'SYHeiBold';
            color: #5f9160;
            .keyBtn {
              width: 70px;
              height: 70px;
              display: inline-block;
              line-height: 70px;
              margin: 0 auto;
              text-align: center;
              background-image: url('../../assets/image/face/letter.png');
              background-repeat: no-repeat;
              background-size: cover;
              font-size: 34px;
              color: #ffffff;
              font-family: 'SYHeiBold';
              cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .memoryTip {
    display: table-cell;
    vertical-align: middle;
    .container {
      ul {
        text-align: center;
        padding: 267px 0;
        li {
          padding: 26px 0;
          p {
            font-size: 42px;
            font-family: 'SYHeiBold';
            color: #5f9160;
            .keyBtn {
              width: 91px;
              height: 91px;
              display: inline-block;
              line-height: 91px;
              margin: 0 auto;
              text-align: center;
              background-image: url('../../assets/image/face/letter.png');
              background-repeat: no-repeat;
              background-size: cover;
              font-size: 44px;
              color: #ffffff;
              font-family: 'SYHeiBold';
              cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
            }
          }
        }
      }
    }
  }
}
</style>
